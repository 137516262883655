import type { AppProps } from 'next/app';

// styles
import '@styles/reset.scss';
import 'bootstrap/dist/css/bootstrap.min.css';
import '@fortawesome/fontawesome-pro/css/all.min.css';
import '@styles/global.scss';

export const App = ({ Component, pageProps }: AppProps): JSX.Element => <Component {...pageProps} />;

export default App;
